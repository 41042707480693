$font-family: "Nunito Sans", sans-serif;
$primary: #004aa0;
$secondary: #302633;
$dark: #031726;
$success: #82bf4c;
$warning: #d37204;
$black: #000 !default;
$border-radius: 5px !default;
$border-radius-sm: 8px !default;
$border-radius-lg: 1rem !default;
$gray-100: #f8f8f8 !default;
$gray-200: #e6e6e6 !default;
$gray-300: #cacaca !default;
$blue-800: #002b58 !default;
$border-color: $gray-200 !default;
$input-border-color: $gray-300 !default;
$gray-50: #f5f5f5 !default;
$light: $gray-200 !default;
$white: #fff !default;
$nav-tabs-link-active-border-color: transparent transparent $primary transparent !default;
$nav-tabs-link-hover-border-color: transparent transparent $primary transparent !default;
$nav-tabs-link-active-color: $primary !default;
$nav-link-color: $black !default;
$nav-tabs-border-width: 3px !default;
$nav-tabs-border-radius: 0px !default;
$box-shadow: 1px 4px 4px rgba($black, 0.15) !default;
$border-color: $gray-200 !default;
$btn-focus-box-shadow: none !important !default;
$input-focus-box-shadow: none !important !default;
$form-check-input-focus-box-shadow: none !important !default;
$form-select-focus-box-shadow: none !important !default;
$form-check-input-border-radius: 0.5rem !default;
$table-cell-padding-y: 0.8rem !default;
$table-cell-padding-x: 0.8rem !default;
$display-font-sizes: (
  1: 5rem,
  2: 4.25rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
) !default;

@import "../scss/bootstrap-scss/bootstrap.scss";
@import url("react-toastify/dist/ReactToastify.css");
@import url("axios-progress-bar/dist/nprogress.css");
@import "./provider-profile.scss";
@import "./stripe.scss";
@import "./carousel.scss";

#blog-content::-webkit-scrollbar {
  width: 1px;
}

#root,
html,
body {
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  font-weight: 500 !important;
  font-family: $font-family;
  overflow-x: hidden;
}

.h-3 {
  height: 3px;
}

.social-icon {
  width: 25px;
  height: 25px;
  margin-top: -4px;
}

.icon-blue {
  color: #004aa0;
}

.icon-green {
  color: #58c745;
}

.max-h-250 {
  max-height: 250px;
}

#blog-content {
  max-height: 780px;
  overflow-y: scroll;
}

.heading-font {
  font-size: 60px !important;
  font-weight: 500 !important;
}

@media (max-width: 768px) {
  .heading-font {
    font-size: 36px !important;
    font-weight: 500 !important;
  }
}

@media (min-width: 1700px) {
  .heading-font {
    font-size: 72px !important;
    font-weight: 500 !important;
  }
}

header {
  background-color: $white;
  position: fixed;
  width: 100%;
  z-index: 1030;
}

.banner {
  width: 100%;
}

.input-group {
  .form-control {
    border-right: none;
  }

  .input-group-text {
    background-color: transparent;
    border-left: none;
  }
}

.title-top {
  padding: 1rem;
  margin-bottom: -105px;
  position: relative;
  z-index: 99;
}

.profile-icon {
  width: 90px;
  height: 90px;
}

.heading-font-404 {
  font-size: 100px !important;
  font-weight: 500 !important;
  color: #004aa0;
}

.title-bottom {
  padding: 1rem;
  margin-top: -105px;
  position: relative;
  z-index: 99;
}

.custom-banner-margin {
  margin-top: 130px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-no-drop {
  cursor: no-drop !important;
}

.cursor-default {
  cursor: default !important;
}

.form-group {
  display: block;
}

.choose-options {
  margin-top: 16px;

  .options {
    padding: 16px;
    border-radius: 32px;
    overflow: hidden;

    &:hover {
      background-color: $white;
    }
  }
}

.overflow-unset {
  overflow-wrap: anywhere !important;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  border: 2px solid $gray-200;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50%;
}

#dropdown-button-drop-up::after {
  display: none;
}

#file-upload #file-icon {
  display: contents !important;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  border: solid $primary;
  border-width: 0 2px 2px 0;
  transform: rotate(0deg);
  background-color: $primary;
  border-radius: 50%;
  background-image: url("../images/check.svg");
  background-repeat: no-repeat;
  background-size: 13px auto;
  background-position: 6px center;
}

.form-group.checkbox label:before {
  border-radius: 6px;
}

.form-group.checkbox input:checked + label:after {
  border-radius: 6px;
}

.count {
  min-width: 24px;
  height: 24px;
  border-radius: 32px;
  display: flex;
  color: $white;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.progress-line {
  display: flex;
  height: 3px;

  div {
    background-color: $white;
    height: 100%;
    width: 16px;
    border-radius: 12px;
    margin-right: 4px;
  }

  .active {
    background-color: $primary;
  }
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.table thead th {
  color: $primary;
}

.border-dotted {
  border: 1px dotted $border-color;
}

.gradient-textbox {
  background: #6cbe46;
  /* IE6-9 */
  padding: 1px;
  border-radius: 50px;
  overflow: hidden;

  .form-control {
    color: $white;
  }

  .form-control-lg,
  .input-group-text {
    border-radius: 50px;
    border: none;
    background-color: $dark;
  }
}

.logo-drawer {
  margin-top: 0.34rem !important;
  margin-bottom: 0.34rem !important;
}

#dropdown-basic1::after {
  display: none !important;
}

.dropdown-desktop {
  min-width: 17rem;
  padding: 0.8 0.5rem;
}

#dropdown-basic1 .nav.span {
  padding-left: 0.8rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: 0.5rem !important;
  margin-bottom: -0.5rem;
}

.left-caret {
  position: relative;

  &::before {
    content: "\f0d9";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    position: absolute;
    left: -9px;
    font-size: 25px;
    top: 0px;
    color: rgba($secondary, 0.1);
  }
}

// Large devices (desktops, 992px and up)
@media (max-width: 572px) {
  .mobilePhoneModal {
    display: block !important;
  }

  .phoneModal {
    display: none !important;
  }
}

.h-75 {
  height: 95% !important;
}

@media (max-width: 991.98px) {
  .mobileNav {
    position: fixed;
    background-color: $white;
    visibility: hidden;
    z-index: 9999;
    top: 0px;
    height: 100%;
    overflow-y: auto;
    width: 280px;
    left: 0px;
  }

  h5.visit {
    padding-top: 5rem !important;
  }

  .blockBackDropMobile {
    display: block;
    font-size: 14px !important;
  }

  .blockBackDropDesktop {
    display: none;
  }
}

.dropdown-desktop .blockBackDropDesktop {
  padding: 0.5rem;
}

@media (min-width: 573px) {
  .mobilePhoneModal {
    display: none !important;
  }

  .phoneModal {
    display: block !important;
  }
}

@media (min-width: 991.98px) {
  .mobileNav {
    visibility: visible !important;
  }

  .blockBackDropMobile {
    display: none !important;
  }

  .blockBackDropDesktop {
    display: block !important;
  }
}

#dropdown-basic1::after {
  display: none !important;
}

.dropdown-desktop {
  min-width: 18rem;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .user-address {
    font-size: 14px;
    word-break: break-word;
  }
}

#nprogress {
  .bar {
    height: 0.5rem;
  }
}

.word-break {
  word-break: break-all;
}

.media-container {
  margin-bottom: 10px;
  position: relative;
  min-height: 150px;
  min-width: auto;
  /* z-index: 1; */
}

.upload-control {
  margin-bottom: 5px;
}

.h-5rem {
  max-height: 5rem;
}

@media screen and (min-width: 1200px) {
  .ask-mt {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 1150px) and (min-width: 991px) {
  .ask-mt {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 576px) and (min-width: 419px) {
  .ask-mt {
    margin-top: 2rem !important;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.logged-in {
  .portal-nav {
    display: block;
  }

  .public-nav {
    display: none;
  }
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.table thead th {
  color: $primary;
}

.fw-semibold {
  font-weight: 600 !important;
}

.bg-success-50 {
  background-color: #caffcc !important;
}

.refer-img {
  max-width: 50px;
  max-height: 50px;
  position: relative;
  overflow: hidden;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .progress-line {
    div {
      width: 10px;
    }
  }

  .banner {
    background-size: auto 100%;
    // height: 15%;
  }

  .h-5rem {
    height: 100% !important;
  }

  .google-img {
    width: 100% !important;
  }

  .chat-img {
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
    min-height: 40px;
    position: relative;
    object-fit: cover;
    border-radius: 50%;
  }

  .conversation-img {
    min-width: 40px !important;
    max-width: 40px !important;
    max-height: 40px !important;
    min-height: 40px !important;
    position: relative !important;
    object-fit: cover !important;
  }

  .user-address {
    font-size: 14px;
  }
}

.refer-img {
  min-width: 45px;
  max-width: 45px;
  max-height: 45px;
  min-height: 45px;
  position: relative;
  object-fit: cover;
  border-radius: 50%;
}

.pdf-doctor-img {
  min-width: 45px;
  max-width: 45px;
  max-height: 45px;
  min-height: 45px;
  position: relative;
  object-fit: cover;
  border-radius: 50%;
}

.object-cover {
  object-fit: cover;
}

.profile-img {
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  min-height: 30px;
  position: relative;
  object-fit: cover;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}

@media (max-width: 680px) {
  .media-container {
    min-width: 100%;
  }
}

.conversation-img {
  min-width: 35px;
  max-width: 35px;
  max-height: 35px;
  min-height: 35px;
  position: relative;
  object-fit: cover;
}

.width-fixed {
  width: 130px !important;
  height: 43px !important;
}

.fixed-width {
  width: 244px !important;
}

.vr {
  align-self: center;
  background-color: #dcdcdc;
}

.fs-6 {
  font-size: 14px !important;
}

.refer-img {
  min-width: 45px;
  max-width: 45px;
  max-height: 45px;
  min-height: 45px;
  position: relative;
  object-fit: cover;
  border-radius: 50%;
}

.pdf-doctor-img {
  min-width: 45px;
  max-width: 45px;
  max-height: 45px;
  min-height: 45px;
  position: relative;
  object-fit: cover;
  border-radius: 50%;
}

.form-bg {
  background-color: #002b58 !important;

  &::placeholder {
    color: $white;
    font-size: 16px;
    font-weight: normal;
  }
}

.education-img {
  min-width: 100%;
  max-width: 100%;
  min-height: 250px;
  max-height: 250px;
  object-fit: cover;
}

@media (max-width: 1199.98px) {
  .logo-resposnive {
    width: 100% !important;
  }
}

.object-cover {
  object-fit: cover;
}

.profile-img {
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  min-height: 30px;
  position: relative;
  object-fit: cover;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}

@media (max-width: 680px) {
  .media-container {
    min-width: 100%;
  }
}

.conversation-img {
  min-width: 35px;
  max-width: 35px;
  max-height: 35px;
  min-height: 35px;
  position: relative;
  object-fit: cover;
}

.width-fixed {
  width: 130px !important;
  height: 43px !important;
}

.hidden-error {
  border-color: #ffffff !important;
}

.fixed-width {
  width: 244px !important;
}

.iframeVideo img {
  max-width: 100% !important;
}

.iframeVideo p {
  font-size: 13px;
  line-height: 1.42;
  font-family: " Helvetica,Arial,sans-serif";
}

.iframeVideo iframe {
  width: 100%;
  height: 590px;
}

.iframeVideoType iframe {
  width: 100%;
  height: 420px;
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .iframeVideo iframe {
    width: 100%;
    height: 510px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .iframeVideo iframe {
    width: 100%;
    height: 450px;
  }
}

@media screen and (max-width: 1199.98px) and (min-width: 992px) {
  .blockBackDropMobile {
    display: none !important;
  }

  .blockBackDropDesktop {
    display: block !important;
    font-size: 12px !important;
  }

  .about-tabview {
    font-size: 12px !important;
    align-self: center;
    align-items: center;
    display: flex;
  }

  h5.visit {
    padding-top: 8.5rem !important;
  }
}

@media screen and (max-width: 1199px) {
  .cols {
    flex: 0 0 auto;
    width: 41.66667%;
    display: none;
  }

  .cols {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .iframeVideo iframe {
    height: 310px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .iframeVideo iframe {
    height: 230px;
    width: 100%;
  }
}

@media screen and (max-width: 576px) and (min-width: 475px) {
  .iframeVideo iframe {
    height: 290px;
    width: 100%;
  }
}

@media screen and (max-width: 474px) and (min-width: 400px) {
  .iframeVideo iframe {
    height: 230px;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .iframeVideo iframe {
    height: 190px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(16, 86, 146, 0.54) 54%,
      rgba(16, 86, 146, 1) 100%
    );
  }

  .user-type {
    position: absolute;
    z-index: 9;
    bottom: 0px;
    width: 100%;
  }
}

.text-justify {
  text-align: justify;
}

.check-box {
  padding-left: 8px;
  display: flex;
  align-items: center;
  height: 100px;
}

@media screen and (max-width: 1400px) {
  .benifits h4 {
    width: 220px;
  }
}

.education-active {
  background-color: #105692 !important;
  color: white !important;
}

//provider
.primary-50 {
  color: #0f3968 !important;
}

.btn-primary-50 {
  background-color: #0f3968 !important;
  color: $white !important;
}

.bg-light-50 {
  background-color: #f3f4f6 !important;
}

select {
  color: #757575 !important;
}

select option {
  color: black !important;
}

.parent-pic {
  position: relative;

  .badge {
    position: absolute;
    top: -4px;
    z-index: 99;
    right: -3px;
  }

  .badge-blue {
    font-size: 10px;
    background-color: #0dcaf0 !important;
    color: #fff !important;
    border-radius: 12px;
    padding: 0px 4px;
    line-height: 16px;
  }

  .provider-pics {
    img {
      height: 180px;
      position: relative;
      width: 180px;
      object-fit: cover;
      border-radius: 100%;
      display: flex;
      margin: 0 auto;
    }
  }
}

.bg-primary-25 {
  background-color: rgba($primary, 0.12);
}

@media (max-width: 600px) {
  .provider-pic {
    img {
      height: 180px;
      position: relative;
      width: 180px;
      object-fit: contain;
      border-radius: 100%;
      display: flex;
      margin: 0 auto;
    }
  }

  .provider-pics {
    img {
      height: 180px;
      position: relative;
      width: 180px;
      object-fit: cover;
      border-radius: 100%;
      display: flex;
      margin: 0 auto;
    }
  }

  .profile-upload-img {
    img {
      width: 100%;
    }
  }
}

.check-box {
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  height: 100px;
}

.radio-buttons {
  display: flex;
  flex-direction: row;
}

.form-radio-label {
  padding-left: 20px;
  padding-bottom: 10px;
}

.education-active {
  background-color: #105692 !important;
  color: white !important;
}

.bg-teal {
  background-color: rgba(#008080, 0.1) !important;
}

button.ql-bold {
  background-color: #000;
  color: white;
}

.ql-bold[title]:after {
  background-color: #000;
  color: #fff;
}

button[aria-label]:hover:after {
  content: attr(aria-label);
  padding: 4px 8px;
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  z-index: 20px;
  background: #000;
  color: #fff;
}

.bg-purple {
  background-color: $blue-200;
}

.z-index {
  z-index: 1;
}

//home

.btn-circle {
  width: 40px;
  height: 40px;
}

@media (max-width: 992px) {
  .user-card {
    min-height: 0px !important;

    .user-type {
      h3 {
        font-size: 20px !important;
      }
    }
  }
}

.border-blue {
  border: 1px solid rgba(#2196f2, 0.49) !important;
}

.box-shadow {
  box-shadow: 1px 4px 4px rgba($black, 0.05) !important;
}

.bg-blue {
  background-color: rgba(#2196f2, 0.25) !important;
}

.socialmedia-btn {
  width: 35px;
  height: 35px;
  display: flex;
  margin: 0 0.5rem;
  justify-content: center;
}

.education-shadow {
  box-shadow: 0px 4px 10px rgba($black, 0.1) !important;
}

.mobileChat {
  display: block;
}

@media (max-width: 769px) {
  .mobileChat {
    display: none;
  }
}

.mobileArrow {
  display: none;
}

@media (max-width: 769px) {
  .mobileArrow {
    display: block;
  }
}

.bg-teal-50 {
  background-color: rgba(#008080, 0.1) !important;
}

.ql-formats button:hover {
  position: relative;
}

button[aria-label]:hover:after {
  content: attr(aria-label);
  padding: 4px 8px;
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  z-index: 20px;
  background: #000;
  color: #fff;
}

@media (max-width: 375px) {
  td {
    font-size: 12px !important;
  }

  h5.visit {
    font-size: 12px;
  }
}

.carousel-control-next {
  justify-content: end;
  top: -100px;

  &.active {
    opacity: 1;
  }
}

.carousel-control-prev {
  justify-content: start;
  top: -100px;

  &.active {
    opacity: 1;
  }
}

.bg-chart {
  background-color: #46b0ba;
}

@media screen and (max-width: 1900px) and (min-width: 427px) {
  .bg-chart {
    padding: 40px 0px !important;
  }
}

@media (max-width: 426px) {
  .bg-chart {
    padding: 10px 0px !important;
  }

  .chart-h4 {
    font-size: 8px !important;
  }

  .chart-register {
    font-size: 8px !important;
  }

  .btn-red {
    i {
      top: 6px;
      font-size: 10px;
      right: 15px;
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 0%;
}

.rounded {
  border-radius: 12px !important;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.task__remove-icon {
  display: none;
}

.cards:hover .task__remove-icon {
  display: block;
}

.bg-green {
  background-color: rgba(#008011, 0.1) !important;
}

.provider-icons {
  width: 50px;
  height: 50px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: $primary;
  background-color: rgba(16, 86, 146, 0.25);
  border-bottom: 5px solid $primary;
  font-weight: bold;
  border-radius: 0px !important;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  font-weight: bold;
}

.provider-sm {
  width: 44px !important;
  height: 40px !important;
}

.membership-pricing-table table .icon-no,
.membership-pricing-table table .icon-yes {
  font-size: 20px;
}

.membership-pricing-table table .icon-no {
  color: #a93717;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  vertical-align: middle;
  margin-top: 2px;
}

.membership-pricing-table table .icon-yes {
  color: #209e61;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  vertical-align: middle;
  margin-top: 2px;
}

.membership-pricing-table table .plan-header {
  text-align: center;
  font-size: 40px;
  padding: 0px 0 25px 0px;
}

.membership-pricing-table table .plan-header-free {
  background-color: #1d9a78;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.membership-pricing-table table .plan-header-blue {
  color: #fff;
  background-color: #1d6fa9;
  border-color: #1d6fa9;
  border-radius: 10px 10px 0px 0px;
}

.membership-pricing-table table .plan-header-light-blue {
  color: #fff;
  background-color: #36afce;
  border-color: #36afce;
  border-radius: 10px 10px 0px 0px;
}

.membership-pricing-table table .plan-header-light-green {
  color: #fff;
  background-color: #8bc145;
  border-color: #8bc145;
  border-radius: 10px 10px 0px 0px;
}

.membership-pricing-table table .plan-header-standard {
  color: #fff;
  background-color: #ff9317;
  border-color: #e37900;
  border-radius: 10px 10px 0px 0px;
}

.membership-pricing-table table td {
  text-align: center;
  width: 15%;
  padding: 10px;
  background-color: #fff;
  font-size: 14px;
  -webkit-box-shadow: 0 1px 0 #fff inset;
  box-shadow: 0 1px 0 #fff inset;
}

.membership-pricing-table table td {
  border: 1px solid #ebebeb;
}

.membership-pricing-table table tr td:first-child {
  background-color: transparent;
  text-align: left;
  width: 25%;
}

.membership-pricing-table table tr td:nth-child(5) {
  background-color: #fff;
}

.membership-pricing-table table tr:first-child td,
.membership-pricing-table table tr:nth-child(2) td {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.membership-pricing-table table tr:first-child th:first-child {
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.membership-pricing-table table tr:first-child th .pricing-plan-name {
  font-size: 16px;
  border-radius: 10px 10px 0px 0px;
}

.membership-pricing-table table tr:first-child th .pricing-plan-price {
  line-height: 42px;
}

.membership-pricing-table table tr:first-child th .pricing-plan-price > sup {
  font-size: 45%;
}

.membership-pricing-table table tr:first-child th .pricing-plan-price > span {
  font-size: 60%;
}

.membership-pricing-table table tr:first-child th .pricing-plan-period {
  margin-top: -7px;
  font-size: 25%;
}

.membership-pricing-table table .header-plan-inner {
  position: relative;
}

.membership-pricing-table table .recommended-plan-ribbon {
  box-sizing: content-box;
  background-color: #dc3b5d;
  color: #fff;
  position: absolute;
  padding: 3px 6px;
  font-size: 11px !important;
  font-weight: 500;
  left: -6px;
  top: -22px;
  z-index: 99;
  width: 100%;
  -webkit-box-shadow: 0 -1px #c2284c inset;
  box-shadow: 0 -1px #c2284c inset;
  text-shadow: 0 -1px #c2284c;
}

.membership-pricing-table table .recommended-plan-ribbon:before {
  border: solid;
  border-color: #c2284c transparent;
  border-width: 6px 0 0 6px;
  bottom: -5px;
  content: "";
  left: 0;
  position: absolute;
  z-index: 90;
}

.membership-pricing-table table .recommended-plan-ribbon:after {
  border: solid;
  border-color: #c2284c transparent;
  border-width: 6px 6px 0 0;
  bottom: -5px;
  content: "";
  right: 0;
  position: absolute;
  z-index: 90;
}

.membership-pricing-table table .plan-head {
  box-sizing: content-box;
  background-color: #ff9c00;
  border: 1px solid #cf7300;
  position: absolute;
  top: -33px;
  left: -1px;
  height: 30px;
  width: 100%;
  border-bottom: none;
}

.pricing-header-free {
  background-color: #16745a;
}

.pricing-header-light-blue {
  background-color: #26849d;
}

.pricing-header-light-green {
  background-color: #699331;
}

.pricing-header-blue {
  background-color: #16537f;
}

.btn-free {
  background-color: #1d9a78 !important;
}

.btn-light-green {
  background-color: #8bc145 !important;
}

.btn-light-blue {
  background-color: #36afce !important;
}

.btn-blue {
  background-color: #1d6fa9 !important;
}

.plan-header::before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgba(0, 0, 0, 0.35) 0,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.35) 0,
    rgba(0, 0, 0, 0) 80%
  );
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}

.plan-header:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

@media (max-width: 768.98px) {
  .membership-pricing-table table .plan-header {
    text-align: center;
    font-size: 28px;
    padding: 0px 0 25px 0px;
  }

  .membership-pricing-table table tr:first-child th .pricing-plan-name {
    font-size: 16px;
    border-radius: 10px 10px 0px 0px;
  }

  .membership-pricing-table table tr:first-child th .pricing-plan-period {
    margin-top: -7px;
    font-size: 35%;
  }

  .btn-outline-light {
    font-size: 12px !important;
    padding: 3px 10px !important;
  }

  .breadcrumb {
    font-size: 15px;
  }
}

.heading {
  font-size: 1.8rem;
}

.fs--18 {
  font-size: 18px;
}

.fs--25 {
  font-size: 25px;
}

.fs--22 {
  font-size: 22px;
}

.fs--12 {
  font-size: 12px;
}

.fs--42 {
  font-size: 42px;
}

.mt-r-5 {
  margin-top: 5rem;
}

.mt-r-6 {
  margin-top: 6rem;
}

.mt-r-1 {
  margin-top: 1rem;
}

.mt-r-3 {
  margin-top: 3rem;
}

.mt-t-1 {
  margin-top: -1.3rem;
}

@media (max-width: 1150px) {
  .wrapper {
    margin-top: 0rem;
  }

  .mt-r-6 {
    margin-top: 5rem;
  }

  .mt-r-3 {
    margin-top: 5rem;
  }

  .mt-r-1 {
    margin-top: 4rem;
  }

  .mt-t-1 {
    margin-top: 0rem;
  }

  .heading {
    font-size: 1.3rem;
  }
}

@media (max-width: 572px) {
  .mobilePlaceholder {
    display: block !important;
  }

  .webPlaceholder {
    display: none !important;
  }
}

@media (min-width: 573px) {
  .mobilePlaceholder {
    display: none !important;
  }
}

.form-select option:disabled {
  color: gray !important;
}

.speech-panel {
  background-color: #e3dfdfa8;
  border-radius: 16px;
  display: inline-block;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 12;
}

.speech1 {
  background-color: #f0f0f0;
  /* border: solid 2px #000; */
  border-radius: 16px;
  display: inline-block;
  margin: 0.5em;
  position: absolute;
  right: 0rem;
  left: 0rem;
  bottom: 0rem;
  top: 0rem;
  height: -moz-fit-content;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  text-align: center;
  padding: 5rem 1.7rem;
  vertical-align: middle;
  color: #002b58 !important;
}

.heading-provider {
  font-size: 1.5rem;
  border-bottom: 3px solid #105692;
  border-radius: 0px;
  padding: 1rem;
  box-shadow: darkkhaki;
}

.thought {
  display: flex;
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;
  min-width: auto;
  max-width: 170px;
  min-height: 40px;
  margin: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  right: 0;
}

.thought:before,
.thought:after {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: -1;
}

.thought:before {
  width: 44px;
  height: 44px;
  top: -12px;
  left: 28px;
  box-shadow: -50px 30px 0 -12px #fff;
}

.thought:after {
  bottom: -10px;
  right: 26px;
  width: 30px;
  height: 30px;
  box-shadow: 40px -34px 0 0 #fff, -28px -6px 0 -2px #fff,
    -24px 17px 0 -6px #fff, -5px 25px 0 -10px #fff;
}

#background-wrap {
  bottom: 0;
  left: 0;
  padding-top: 25px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

/* ANIMATIONS */

.x4 {
  -webkit-animation: animateCloud 18s linear infinite;
  -moz-animation: animateCloud 18s linear infinite;
  animation: animateCloud 18s linear infinite;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

/* OBJECTS */

.bs-tooltip-bottom {
  transform: translate3d(690.5px, 300px, 0px) !important;
}

@media (max-width: 768px) {
  .bs-tooltip-bottom {
    transform: translate3d(150.5px, 402px, 0px) !important;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #a39c9c !important;
}

input[type="file"] {
  font-size: 0;
}

::file-selector-button {
  font-size: initial;
}

// ****feeds****
.circle-feeds {
  i {
    font-size: 6px;
  }
}

//testimonial
.bg-light-primary {
  background-color: #ebf7f6;
}

.carousel-indicators {
  bottom: -60px !important;
}

.carousel-indicators [data-bs-target] {
  background-color: white !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}

button[aria-label]:hover:after {
  background-color: transparent !important;
}

.card {
  height: 18rem;
}

@media (max-width: 304px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 144px;
  }
}

@media screen and (min-width: 305px) and (max-width: 344px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 168px;
  }
}

@media screen and (min-width: 345px) and (max-width: 367px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }
}

@media screen and (min-width: 368px) and (max-width: 380px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 218px;
  }
}

@media screen and (min-width: 381px) and (max-width: 392px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 230px;
  }
}

@media screen and (min-width: 393px) and (max-width: 436px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
  }
}

@media screen and (min-width: 436px) and (max-width: 540px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 268px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 268px;
  }
}

.exercises_img {
  min-height: 200px;
  position: relative;
  overflow: hidden;
}

.exercise-card {
  height: 560px;
}

.exercise-heading {
  height: 80px;
}

.exercise-summary {
  height: 180px;
}

.like-icons {
  background-color: #e7e7e7;
  width: 28px;
  height: 28px;
  position: absolute;
  z-index: 9;
  top: 0px;
  right: 0px;
  left: 0px;
  border-radius: 50%;
  margin: 10px;
}

.badge {
  top: -4px;
  z-index: 99;
  right: -3px;
}

.badge-warning {
  border: 1px solid #ff9317;
  color: #ff9317;
}

.news-img {
  width: fit-content;
  height: auto;
  position: relative;

  img {
    height: inherit;
  }

  .video-play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
  }

  .banner-image {
    max-height: 100%;
    max-width: 100%;
  }
}

#dropdown-basic-exercise::after {
  display: none !important;
}

#exercise-search .dropdown-menu {
  transform: translate(0, 52px) !important;
}

@media (max-width: 767px) {
  .exercise-card {
    height: auto;
  }

  .show-more {
    color: #002b58;
    cursor: pointer;
  }

  .show-more:hover {
    text-decoration: underline;
  }
}

.press-video video {
  border-radius: 12px;
}

.press p {
  word-break: break-all;
}

#press_data {
  font-weight: 200;
}

#chat-dropdown .dropdown-toggle::after {
  display: none;
}

#video-card #play-icon {
  background-color: #0000005e;
}

.play-icon-press {
  height: 60px;
  width: 60px;
  background: linear-gradient(
    0deg,
    #000000 0%,
    rgba(84, 84, 84, 0) 100%
  ) !important;
}

.play-icon {
  height: 35px !important;
  width: 35px !important;
}

.wrapper {
  margin-top: 6rem;
  min-height: 26rem;
}

//new-home-page
@media (max-width: 768px) {
  .Banner .carousel-indicators {
    bottom: 10px !important;
  }
}

//******LAnding page*********//
.landing-page-card {
  width: 90px;
  height: 90px;
}

.progress-line-container {
  width: 100%;
  height: 10px;
  border-radius: 6px;
  background-color: #e0e0e0;
}

.progress-line {
  height: 100%;
  border-radius: 5px;
  background-color: #007bff;
  transition: width 1s ease-in-out;
}

.cancel-button-class {
  background-color: #004aa0 !important;
  border-radius: 10rem !important;
  margin-left: 15px !important;
  font-weight: 800 !important;
}

.confirm-button-class {
  border-radius: 10rem !important;
}

//**********new-home-page**********//
#home-btn {
  .btn {
    width: 138px;
    padding: 8px 0px;
  }
}

.navbar {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.nav-item {
  padding-right: 60px;
}

a.nav-link {
  font-weight: 700;
}

a.nav-link.active {
  background-color: rgba($primary, 0.1);
  border-radius: 40px;
  color: $primary;
  padding: 8px 20px !important;
}

.navigation-web.dropdown-toggle::after {
  content: none !important;
}

.navigation-web.dropdown-toggle.show.span::after {
  color: $primary !important;
}

.pre-line {
  white-space: pre-line;
}

.Banner {
  .carousel-indicators {
    bottom: 0 !important;
    z-index: 200000;
  }

  .carousel-caption {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translateX(-50%) translateY(-50%);
    width: 66%;
    z-index: 1;
    margin-top: 50px;
    text-align: start;

    .vr {
      width: 2px !important;
    }

    .fs-16 {
      font-size: 16px !important;
    }

    .btn-light {
      padding: 12px 54px;
    }
  }
}

.fw-800 {
  font-weight: 800 !important;
}

.text-green-50 {
  color: #59c745 !important;
}

.text-blue {
  color: #2a6dea !important;
}

.sec_two {
  .bg-light-75 {
    background-color: #f0f0f0 !important;
  }

  .btn-success {
    background-color: #59c745 !important;
    border-color: #59c745 !important;
  }

  .home-profile-img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }

  .btn-blue-75 {
    background-color: #2a6dea !important;
  }

  .educational_img {
    border-radius: 10px;
    min-height: 90px;
    height: 7.3rem;
    overflow: hidden;
    position: relative;
    width: fit-content;
    display: flex;
    margin: 0 auto;

    img {
      border-radius: 10px;
    }

    .card-img-top {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .educational_content {
      z-index: 9;
      padding: 8px 16px;
      opacity: 1;
    }

    &::after {
      background: linear-gradient(0deg, #000000 0%, rgba(84, 84, 84, 0) 100%);
      bottom: 0;
      content: "";
      display: block;
      height: 44%;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
      opacity: 0.39;
      z-index: 1;
      color: white;
      border-radius: 0px 0px 10px 10px;
    }
  }

  .zoom-img {
    height: auto;
    overflow: hidden;
    transform: rotate(-14deg) scale(1.1);
    margin-top: -30px;

    img {
      width: 100%;

      &:hover {
        margin-top: 40px;
        height: 450px;
        width: 100%;
        transform: scale(1.2);
      }
    }
  }

  .phone-card {
    height: 587px;
    position: relative;
  }

  .phone-arrow {
    margin-top: -27px;
    position: absolute;
    right: 1.5rem;
    bottom: 0.8rem;
  }

  #carouselExampleControls {
    height: 110px;
  }

  .educational_img {
    &:hover {
      transform: scale(1.2);
      margin-top: 1rem;
    }
  }

  .carousel-indicators [data-bs-target] {
    background-color: #000000 !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    margin-right: 6px !important;
    margin-left: 6px !important;
    opacity: 0.12;
  }

  .carousel-indicators .active {
    opacity: 1 !important;
  }
}

.educational-animation:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: #f5f5f7;
  cursor: pointer;
}

.content-animation:hover {
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: transparent;
  cursor: pointer;
}

.latest-animation:hover {
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: transparent;
  cursor: pointer;
  border-radius: 20px !important;
}

.latests-animation:hover {
  transform: scale(1.025);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: transparent;
  cursor: pointer;
  margin-top: 1rem;
  border-radius: 20px !important;
}

.container-fluid {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.sec_three {
  padding: 130px 0px;

  .bg-light-75 {
    background-color: #f0f0f0 !important;
  }

  .content_02 {
    padding: 130px 0px 0px 0px;
  }

  .content_03 {
    padding: 130px 0px 0px 0px;
  }

  .text-body-tertiary {
    color: #afafaf !important;
  }

  .btn-primary {
    padding: 12px 54px;
  }

  div::-webkit-scrollbar {
    display: none;
  }

  .slider {
    width: 2179px;
    overflow: visible;
    padding-left: 72px;
  }

  .evaluate_img {
    border-radius: 0px 0px 10px 10px;
    min-height: 190px;
    overflow: hidden;
    position: relative;
    margin-right: 24px;
    cursor: pointer;
    display: flex;
    min-width: 280px;

    .card-img-top {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
    }

    .evaluate_content {
      bottom: 0;
      position: absolute;
      width: 100%;
      z-index: 9;
      padding: 8px 16px;
      opacity: 1;
    }

    &::after {
      background: linear-gradient(0deg, #000000 0%, rgba(84, 84, 84, 0) 100%);
      bottom: 0;
      content: "";
      display: block;
      height: 30%;
      left: 0;
      position: absolute;
      width: 100%;
      opacity: 0.39;
      z-index: 1;
      border-radius: 0px 0px 8px 8px;
    }
  }

  .evaluate_img_evaluation {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-right: 24px;
    cursor: pointer;
    width: 22%;
    height: 205px;
    display: flex;
    margin: 0 auto;
    // width: 315px;

    .card-img-top {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
    }

    .evaluate_content {
      bottom: 0;
      position: absolute;
      width: 100%;
      z-index: 9;
      padding: 8px 16px;
      opacity: 1;
    }

    &::after {
      background: linear-gradient(0deg, #000000 0%, rgba(84, 84, 84, 0) 100%);
      bottom: 0;
      content: "";
      display: block;
      height: 30%;
      left: 0;
      position: absolute;
      width: 100%;
      opacity: 0.39;
      z-index: 1;
      border-radius: 0px 0px 8px 8px;
    }
  }
}

.horizontal-line {
  border: 3px solid #222324;
  background-color: #a6a9ae;
  width: 2px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.straight-line {
  border: 2px solid #a6a9ae;
  background-color: #a6a9ae;
  height: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 10px;
}

.symptom-checker:hover {
  color: white !important;
}

.min-360 {
  min-height: 360px;
}

.evaluatin-card {
  height: 214px;
  width: 290px;
}

.sec_four {
  padding: 60px 0px;
  background-color: rgba($primary, 0.04);

  .nav-tabs {
    .nav-item {
      .nav-link {
        font-weight: 500;
        color: rgba($primary, 0.45);
        font-weight: bold !important;

        &.active {
          border-bottom: 2px solid $primary !important;
          font-weight: 600;
          background-color: transparent;
          color: $primary;
        }
      }
    }
  }

  .text-body-tertiary {
    color: #afafaf !important;
  }

  .proven-tab {
    margin-top: 60px;
  }
}

.sec_five {
  padding: 100px 0px;
  background-color: #fff;

  .carousel-indicators [data-bs-target] {
    background-color: #000000 !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    margin-right: 6px !important;
    margin-left: 6px !important;
    opacity: 0.12;
  }

  .carousel-indicators .active {
    opacity: 1 !important;
  }

  .carousel-indicators {
    bottom: 0px !important;
  }

  .topimg {
    left: 9rem;
    top: -3rem;
    z-index: 6;
    position: absolute;
  }

  .text-dark-blue {
    color: #272746 !important;
  }

  .testimonials_title {
    padding-left: 150px;
  }

  .testimonials_img {
    border-radius: 20px;
    min-height: 190px;
    overflow: hidden;
    position: relative;
  }

  .testimonials_img img {
    text-align: center;
    border-radius: 20px;
    min-height: 480px;
    overflow: hidden;
    position: relative;
    background: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #80808029;
    object-fit: contain;
    max-width: 290px;
    padding: 0 1rem;
  }

  .card {
    width: 535px;
    position: relative;
    padding: 60px 40px 32px 40px;
    border-radius: 0px 20px 20px 0px;
    box-shadow: 0px 0.25px 3px rgba($black, 0.16) !important;
    // z-index: -1;
    margin-top: 80px;

    .blockquote-custom {
      position: relative;
    }

    .blockquote-custom-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -20px;
      right: 60px;
    }
  }
}

.sec_six {
  padding: 60px 0px;
  background-color: #f0f7fe;

  .btn-primary {
    padding: 12px 54px;
  }

  .nav-tabs {
    .nav-item {
      .nav-link {
        font-weight: 500;
        color: rgba($primary, 0.45);
        font-weight: bold !important;

        &.active {
          border-bottom: 2px solid $primary !important;
          font-weight: 600;
          background-color: transparent;
          color: $primary;
        }
      }
    }
  }

  .accordion-item {
    border: none !important;
    border-radius: 12px;

    &:first-of-type {
      border-radius: 12px;
    }

    .accordion-header {
      &:hover {
        filter: drop-shadow(-5px -5px -12px rgba(0, 0, 0, 0.6));
      }

      .accordion-button {
        font-size: 18px;
        color: #000000;
        border-radius: 12px;
        padding: 1.5rem 1.25rem;

        &::after {
          background-image: url(../images/arrow-down.svg) !important;
          margin-top: 8px;
        }

        &:hover {
          filter: drop-shadow(-5px -5px -12px rgba(0, 0, 0, 0.6));
        }
      }
    }
  }
}

.accordion-item:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.accordion-button:not(.collapsed)::after {
  margin-top: 0px !important;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 12px;
}

.sec_seven {
  padding: 95px 0px 0px 0px;

  .btn-primary {
    padding: 12px 54px;
  }

  div::-webkit-scrollbar {
    display: none;
  }

  .slider {
    width: 1900px;
    overflow: visible;
    padding-left: 40px;
  }

  .evaluate_img {
    border-radius: 0px 0px 10px 10px;
    min-height: 170px;
    overflow: hidden;
    position: relative;
    margin-right: 40px;
    display: flex;
    min-width: 355px;

    .card-img-top {
      // border-top-left-radius: 20px;
      // border-top-right-radius: 20px;
      border-radius: 20px;
      width: 355px;
      cursor: pointer;
    }

    .evaluate_content {
      bottom: 0;
      position: absolute;
      width: 100%;
      z-index: 9;
      padding: 8px 16px;
      opacity: 1;

      .text-white-65 {
        color: rgba(#ffffff, 0.65);
      }
    }

    &::after {
      background: linear-gradient(0deg, #000000 0%, rgba(84, 84, 84, 0) 100%);
      bottom: 0;
      content: "";
      display: block;
      height: 30%;
      left: 0;
      position: absolute;
      width: 100%;
      opacity: 0.39;
      z-index: 1;
      border-radius: 0px 0px 20px 20px;
    }
  }
}

.min-w-100 {
  min-width: 100% !important;
}

.sec_eight {
  padding: 130px 0px;

  .text-light-75 {
    color: #b5b5b5 !important;
  }

  .btn-primary {
    padding: 12px 54px;
  }
}

.sec_nine {
  padding: 80px 45px;

  .form-control {
    padding: 0.5rem 0.75rem;
  }

  .btn-primary-50 {
    padding: 12px 85px;
    background-color: #1a5caa !important;
    color: #6390c5;
  }
}

footer {
  padding: 100px 0px 0px 0px;

  .footer-pad {
    padding-bottom: 120px;
  }

  .form-control {
    background-color: #f8f8f8;
    color: #000000;
    font-size: 14px !important;
    border-radius: 8px !important;

    &::placeholder {
      color: #bababa !important;
    }
  }

  .text-muted-50 {
    color: #6f7170;
  }
}

.max-h-600 {
  max-height: 590px;
}

.chat {
  background-color: $primary;
  color: white;
  border-radius: 50%;
  border-color: $primary;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  margin-right: 16px;
}

#mybutton {
  position: fixed;
  bottom: -4px;
  right: 10px;
  z-index: 99999;
}

.news-background {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  border-radius: 20px !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.grey_box {
  min-width: 260px;
}

@media (max-width: 1200px) {
  .nav-item {
    padding-right: 24px;
  }

  #home-btn {
    .btn {
      width: auto;
      padding: 8px 0px;
    }
  }
}

.sec_one,
.sec_two,
.sec_three,
.sec_five,
.sec_seven,
.sec_eight,
.sec_nine,
footer {
  background-color: $white;
}

.sec_six {
  background-color: #f0f7fe;
}

.sec_four {
  background-color: #f5f8fb;
}

.mobile-heading {
  font-size: 72px !important;
  font-weight: 500 !important;
}

@media (max-width: 768px) {
  .mobile-heading {
    font-size: 31px !important;
    font-weight: 500 !important;
  }

  .sec_two .phone-card {
    height: auto;
    position: relative;
  }

  .sec_five .card .blockquote-custom-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 40px;
  }

  .blockquote-custom-icon img {
    width: 9rem;
    height: 2rem;
  }

  .navbar-nav .nav-link {
    padding: 8px 20px !important;
    margin: 4px 0px;
  }

  .banner_img {
    img {
      min-height: 570px;
      max-height: 570px;
      object-fit: cover;
    }
  }

  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sec_two {
    .carousel-indicators {
      bottom: -40px !important;
    }
  }

  .sec_five .card {
    width: 435px;
  }

  .sec_five .carousel-indicators {
    bottom: -60px !important;
  }

  .sec_five .testimonial_left {
    left: 0px;
  }
}

@media (max-width: 576px) {
  .sec_one {
    padding: 100px 0px;
  }

  .sec_three {
    padding: 100px 0px;
  }

  .sec_eight {
    padding: 100px 0px;
  }

  .sec_nine {
    padding: 0px 30px 50px 30px;
  }

  .sec_one,
  .sec_three,
  .sec_four,
  .sec_five,
  .sec_six,
  .sec_eight {
    .btn-primary {
      font-size: 16px;
    }
  }

  .sec_nine {
    .btn-primary-50 {
      font-size: 16px;
    }

    a {
      font-size: 15px;
    }
  }

  .proven-tab p {
    font-size: 16px;
  }

  .content_02,
  .content_01,
  .content_03 h5 {
    font-size: 16px;
  }

  .sec_five .card {
    width: 100%;
    height: 80%;
    padding: 30px;
    right: 65px;
    z-index: 1;
    border-radius: 20px;
    margin-top: 50px;
  }

  .topimg h5 {
    font-size: 16px;
  }

  .testimonials_title {
    padding-left: 0px !important;
  }

  .sec_five .testimonials_img {
    border-radius: 12px;
  }

  .sec_five .logo_testimonials {
    width: 150px;
  }

  .sec_five .topimg {
    top: -1rem;
    left: 0px;
  }

  .sec_six {
    h1 {
      font-size: 24px;
    }
  }

  .sec_six {
    .accordion-header {
      .accordion-button {
        font-size: 16px !important;
      }
    }
  }

  .sec_three,
  .sec_seven {
    .slider {
      padding-left: 20px !important;
      width: 100%;
      padding-bottom: 1rem;
    }
  }

  footer {
    padding: 60px 0px 0px 0px !important;

    .footer-pad {
      padding-bottom: 35px !important;
    }
  }

  .sec_seven {
    .evaluate_img {
      min-width: 285px !important;
      margin-right: 24px;
      height: 100% !important;

      .card-img-top {
        width: 285px !important;
      }
    }
  }
}

#press-video video {
  max-height: 550px;
}

@media (max-width: 320px) {
  .sec_three {
    .evaluate_img {
      min-width: 265px;

      .card-img-top {
        width: 265px;
      }
    }

    .grey_box {
      min-width: 255px;
    }
  }

  .sec_seven {
    .evaluate_img {
      min-width: 285px !important;
      margin-right: 24px;

      .card-img-top {
        width: 285px !important;
      }
    }
  }
}

.min-w-150 {
  min-width: 150px;
}

.min-h-25 {
  min-height: 26rem;
}

@media (min-width: 769px) and (max-width: 1000px) {
  .display-2 {
    font-size: calc(1.575rem + 1.9vw);
  }
}

#search .fa-magnifying-glass {
  background-color: #004aa0;
  color: white;
}

#player-id iframe {
  border-radius: 16px;
}

.h-35 {
  height: 35px;
}

#education-card .dropdown-menu {
  width: 315px;
}

#education-basic-button {
  background: #f5f5f7;
  border: none;
  color: black;
  padding: 0;
}

#education-basic-button::after {
  display: none;
}

.evaluate_img_education img {
  width: 300px;
  height: 200px;
}

.text-body-tertiary {
  color: #afafaf !important;
}

.swal2-styled.swal2-confirm,
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #004aa0 !important;
  margin: 0 !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-deny,
.swal2-styled.swal2-cancel {
  padding: 0.7rem 2rem !important;
  font-size: 18px !important;
}

.swal2-icon.swal2-success .swal2-success-ring,
.swal2-info {
  border: 0.25em solid #004aa0 !important;
}

.swal2-info {
  color: #004aa0 !important;
}

.swal2-actions {
  border-radius: 13px !important;
}

.swal2-title {
  color: initial !important;
}

.react-share__ShareButton::after {
  display: none;
}

.phone-card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.more-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1rem;
  flex: 1;
}

.profile-more::after {
  display: none !important;
}

.border-5-bottom {
  border-bottom: 5px solid #004aa0 !important;
  width: fit-content;
  font-size: 18px;
}

#home-tab video,
iframe {
  border-radius: 20px;
  background: #f5f5f7;
}

.profile-banner {
  width: 330px;
  height: 230px;
}

@media (max-width: 768px) {
  .sec_two {
    .zoom-img {
      height: auto;
      overflow: hidden;
      transform: rotate(-14deg) scale(1.1);
      margin-top: 0px;

      img {
        width: 100%;

        &:hover {
          margin-top: 45px;
          height: 100%;
          width: 100%;
          transform: scale(1.2);
        }
      }
    }
  }
}

#dashboard-table .table-responsive::-webkit-scrollbar {
  width: 1px;
}

.plays-icon video {
  object-fit: cover;
  border-radius: 16px;
}

.bg-light-sky {
  background-color: #e5edf5;
}

.banner_img_first {
  img {
    transform: scale(0.6);
    margin-top: -8rem;
  }
}

.banner_first .carousel-caption {
  top: 40%;
}

.scroll-div {
  overflow-y: scroll !important;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .banner_img_first {
    img {
      min-height: 100%;
      max-height: 570px;
      object-fit: cover;
      margin-top: 0rem;
    }
  }

  .banner_img {
    img {
      min-height: 570px;
      max-height: 570px;
      object-fit: cover;
    }
  }

  .Banner .carousel-caption {
    top: 35%;
    left: 45%;
    width: 83%;
    padding-top: 0px !important;
    margin-top: 0px !important;
    z-index: 10000;
  }

  .-mt-15 {
    margin-top: -15px;
  }
}

.news-img video {
  border-radius: 8px;
}

.home-banner:before {
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  height: 93%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.custom-index {
  position: relative;
  z-index: 99;
}

.min-h-21 {
  min-height: 16.5rem;
}

.banner-home-card {
  height: 100%;
  width: 100%;
  border-radius: 1rem !important;
}

.contactus-img {
  width: 180px;
  height: 100%;
  margin: auto;
}

.contactus-img-patient {
  width: 115px;
  height: 100%;
  margin-top: -0.5rem !important;
}

.contactus-img-patient img {
  width: inherit;
}

@media (max-width: 767px) {
  .background-light {
    background: #00000024;
  }

  .contactus-img-patient {
    width: 150px;
    height: 100%;
    margin: auto;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1750px) {
  .banner-home-card {
    height: 100%;
    width: 100%;
    border-radius: 1rem !important;
  }
}

@media (max-width: 768px) {
  .home-banner:before {
    height: 100%;
  }
}

.rem-94 {
  padding: 0.94rem;
}

.input-bar {
  font-size: 18px !important;
  padding: 0.66rem !important;
}

.abosulte-heading {
  top: 12.3rem;
  left: 1rem;
}

.remove-space {
  top: 18rem;
  left: 1rem;
}

.jump-button {
  padding: 12px 35px;
  // font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s;
  /* Add a transition for the transform property */
}

/* Define the animation on hover */

.jump-button:hover {
  transform: scale(0.9);

  animation: one 3s infinite;

  animation-direction: alternate;
}

.password-toggle-red {
  font-size: 30px;
  color: red;
  margin-right: 5px;
}

.password-toggle-green {
  font-size: 30px;
  color: green;
  margin-right: 5px;
}

.abosulte-large-heading {
  top: 12.3rem;
  left: 1rem;
}

.fs-14px {
  font-size: 14px;
}

.fs-21px {
  font-size: 72px !important;
  font-weight: 500px !important;
}

.desktops-heading {
  display: flex;
  font-size: 29px;
}

.mobiles-heading {
  display: none;
}

@media (max-width: 768px) {
  .abosulte-heading {
    top: 14.3rem;
    left: 1rem;
  }

  .text-cutoff {
    max-height: 9rem;
    overflow-y: scroll;
  }

  .sec_five .card {
    width: 100%;
    min-height: 280px !important;
    max-height: 65% !important;
  }

  .fs-21px {
    font-size: 34px !important;
    font-weight: 500px !important;
  }

  .abosulte-large-heading {
    top: 90%;
    left: 1.4rem;
  }

  .remove-space {
    top: 14rem;
    left: 1rem;
  }

  .desktops-heading {
    display: none;
  }

  .mobiles-heading {
    display: flex;
  }
}

@media (min-width: 769px) and(max-width:1270px) {
  .abosulte-heading {
    top: 14.3rem;
    left: 1.3rem;
  }

  .abosulte-large-heading {
    top: 10.3rem;
    left: 1rem;
  }

  .remove-space {
    top: 14rem;
    left: 1rem;
  }

  .contactus-img-patient {
    width: 150px;
    height: 100%;
    margin: -2rem 2rem;
  }
}

@media (min-width: 769px) and(max-width:1280px) {
  .desktops-heading {
    display: none;
  }

  .mobiles-heading {
    display: flex;
    font-size: 24px;
  }
}

@media (min-width: 1290px) {
  .remove-space {
    top: 21rem;
    left: 1rem;
  }
}

.bg-light-sky {
  min-height: 600px;
}

.card-scroll {
  max-height: 100%;
  overflow-y: hidden;
  min-height: 250px;
}

.container-sticky {
  position: sticky;
  top: 75px;
  background: white;
  z-index: 100;
}

@media (max-width: 767px) {
  .card-scroll {
    max-height: 100%;
    overflow-y: hidden;
  }
}

.loginor {
  font-size: 12px;
}

.loginor::after,
.loginor::before {
  content: "";
  border-bottom: 1px solid #c2c8d0;
  flex: 1 0 auto;
  margin: 0;
}

.loginor span {
  text-align: center;
  flex: 0.2 0 auto;
  margin: 0;
}

.loginbtn {
  background-color: white !important;
  border: 1px solid #c2c8d0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  box-shadow: none !important;
  color: inherit !important;
}

///// hew home
///

header {
  background-color: $white;
  position: fixed;
  width: 100%;
  z-index: 1030;
  top: 0px;
}

.bg-primary-50 {
  background-color: rgba(0, 74, 160, 0.1);
}

.text-primary-50 {
  color: #004aa0;
  font-weight: 700;
}

.w-80 {
  width: 80px;
}

.h-80 {
  height: 80px;
}

.w-fit {
  width: fit-content;
}

.padding-rem {
  padding-top: 0.7rem;
}

#education,
.wk-section img {
  width: -webkit-fill-available;
}

.tab-menu {
  padding: 0.25rem 1rem;
  width: 100%;
  min-width: 15rem;
}

.active-tab {
  color: #fff;
  text-decoration: none;
  background-color: #004aa0;
}

.patient-appointment th:first-child,
td:first-child {
  position: sticky !important;
  left: -2px;
  background-color: white !important;
}

.text-body-tertiary {
  color: #afafaf !important;
}

.text-dark-blue {
  color: #004aa0;
}

.bg-super-light {
  background-color: #f8f9fa;
}

.fs-18px {
  font-size: 16px !important;
}

.fs-28px {
  font-size: 28px;
}

.h24-5rem {
  height: 24.5rem;
}

.about-img-card {
  width: 110px;
  height: 110px;
  margin-bottom: 1rem;
}

.about-img-card-home {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;

  img {
    margin-top: 1rem;
    min-width: 130px;
    min-height: 130px;
  }
}

.about-magnifying {
  img {
    margin-top: 1rem;
    min-width: 100px;
    min-height: 100px;
  }
}

.border-dotted {
  border: 1px dotted $border-color;
}

.scroll-home {
  overflow-x: scroll;
  overflow-y: hidden;
}

.sec_seven .evaluate_img .card-img-top {
  cursor: pointer;
  height: 100%;
  object-fit: contain;
  margin: 0rem 0 0.3rem 0rem;
  width: 100% !important;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 767.98px) {
  .about-magnifying {
    img {
      margin-top: 1rem;
      min-width: 120px;
      min-height: 120px;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  .user-address {
    font-size: 14px;
    word-break: break-word;
  }

  .pain-level {
    display: flex;

    .pain {
      width: 38px;
      height: 38px;
      min-width: 38px;
      min-height: 38px;
      margin-right: 5px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      cursor: pointer;
      border: 2px solid $gray-200;
    }

    .level-1 {
      border-color: #85c14b;

      &.active {
        background-color: #85c14b;
      }
    }

    .level-2 {
      border-color: #95b634;

      &.active {
        background-color: #95b634;
      }
    }

    .level-3 {
      border-color: #95b634;

      &.active {
        background-color: #95b634;
      }
    }

    .level-4 {
      border-color: #b09c01;

      &.active {
        background-color: #b09c01;
      }
    }

    .level-5 {
      border-color: #bb8e00;

      &.active {
        background-color: #bb8e00;
      }
    }

    .level-6 {
      border-color: #c47f00;

      &.active {
        background-color: #c47f00;
      }
    }

    .level-7 {
      border-color: #cc6f04;

      &.active {
        background-color: #cc6f04;
      }
    }

    .level-8 {
      border-color: #d25e18;

      &.active {
        background-color: #d25e18;
      }
    }

    .level-9 {
      border-color: #d64b27;

      &.active {
        background-color: #d64b27;
      }
    }

    .level-10 {
      border-color: #d73535;

      &.active {
        background-color: #d73535;
      }
    }

    .level-selected-color {
      background-color: $primary;
      border: 1px solid $primary;
      color: white;
    }
  }

  #nprogress {
    .bar {
      height: 0.5rem;
    }
  }
}

.user-card {
  height: 220px;
  overflow: hidden;
  border-radius: 8px !important;
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 50%;
    width: 100%;
    bottom: 0px;
    left: 0px;
  }

  .user-type {
    position: absolute;
    z-index: 9;
    bottom: 0px;
    width: 100%;
  }
}

@media (max-width: 569px) {
  .mobileArrow {
    display: block;
  }
}

@media (max-width: 768px) {
  .provider-pics {
    img {
      height: 130px !important;
      position: relative;
      width: 130px !important;
      object-fit: cover;
      border-radius: 100%;
      display: flex;
      margin: 0 auto;
    }
  }
}

.social-media-links {
  td {
    font-size: 8px !important;
  }
}

.ipadMessage {
  min-height: 55vh;
  max-height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem;
}

.bg-dark-blue-50 {
  background-color: #024891 !important;
}

.padding-left-5px {
  padding-left: 5px;
}

.border-top-color-grey {
  border-top-color: #e5e5e7 !important;
}

.btn-indigo {
  background-color: #9435eb !important;
  float: right;
}

.-ml-32 {
  margin-left: -32px;
}

.text-body-tertiary {
  color: #afafaf !important;
}

.welcome-heading {
  font-size: 4rem;
}

.border-top-right-radius {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

@media (max-width: 768px) {
  .welcome-heading {
    font-size: 3rem;
    text-align: center;
  }

  .sec_two #carouselExampleControls {
    height: 100%;
  }

  .position-fixed {
    position: relative !important;
    margin-top: 5rem;
  }

  .evaluate_img_evaluation {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-right: 24px;
    cursor: pointer;
    width: 90% !important;
    height: 100% !important;

    img {
      width: 100%;
      height: 100%;
    }

    // display: flex;
    // width: 315px;

    .card-img-top {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 315px;
    }

    .evaluate_content {
      bottom: 0;
      position: absolute;
      width: 100%;
      z-index: 9;
      padding: 8px 16px;
      opacity: 1;
    }
  }

  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sec_two {
    .carousel-indicators {
      bottom: -40px !important;
      // right: 50px;
    }
  }

  .sec_five .card {
    width: 255px !important;
  }

  .sec_five .carousel-indicators {
    bottom: -60px !important;
  }

  .sec_five .testimonial_left {
    left: 0px;
  }
}

@media screen and (max-width: 800px) and (min-width: 768px) {
  .sec_five {
    .card {
      margin-top: 30px !important;
    }
  }

  .sec_five .carousel-indicators {
    bottom: -60px !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 801px) {
  .sec_five .carousel-indicators {
    bottom: -60px !important;
  }

  .sec_two .educational_img {
    min-height: 70px;
    display: flex;
  }
}

.sec_seven .press-card::after {
  background: linear-gradient(0deg, #000000 0%, rgba(84, 84, 84, 0) 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 60%;
  left: 0;
  position: absolute;
  width: 100%;
  opacity: 0.39;
  z-index: 1;
  border-radius: 0px 0px 20px 20px;
}

.press-card-video {
  width: 100%;
  height: 225px !important;
  border-radius: 24px !important;
}

.back-light {
  background: #f8f9fa;
}

.footer-heading {
  margin-bottom: 1.2rem;
}

@media (max-width: 768px) {
  .press-card {
    width: 285px !important;
    height: 235px !important;
    padding-top: 2rem !important;
  }

  .press-card-video {
    width: 100%;
    height: 175px !important;
    border-radius: 24px !important;
  }
}

.sec_seven .press-card-video::after {
  // background: #000000;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  opacity: 0.39;
  z-index: 1;
  border-radius: 20px;
}

@media (min-width: 2200px) {
  .sec_nine {
    padding: 50px;
  }
}

th:first-child,
td:first-child {
  position: sticky !important;
  left: -2px;
  z-index: 99;
  background-color: white !important;
}

.question-section ::after {
  display: none !important;
}

.question-section .dropdown-menu {
  height: 20rem;
  overflow-y: scroll;
}

#search .fa-magnifying-glass {
  background-color: #004aa0;
  color: white;
  // padding: 0.7rem 0.4rem;
  margin-left: -10px;
  margin-top: 0;
}

#player-id iframe {
  border-radius: 16px;
}

#education-basic-button::after {
  display: none;
}

#education-basic-button {
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
}

#education-card-mop .dropdown {
  margin-top: 9px;
}

.h-23 {
  height: 23rem;
}

.h-26 {
  height: 26rem;
}

@media (max-width: 768px) {
  .h-23,
  .h-26 {
    height: auto;
  }

  .evaluate_content {
    text-align: left;
  }
}

#education-card-mop .dropdown-menu,
#education-card .dropdown-menu {
  width: 350px;
}

.evaluate_img_education img {
  width: 312px;
  height: 200px;
}

@media (max-width: 768px) {
  .evaluate_img_education img {
    width: 100%;
    height: auto;
  }
}

.border-22 {
  border-radius: 22px !important;
}

.same-button {
  width: 270px;
  height: 64px;
}

.fa-lg {
  font-size: 1.8em !important;
  margin: 0 1rem;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fw-600 {
  font-weight: 600 !important;
}

@media (max-width: 768px) {
  .Banner .carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .sec_five {
    .testimonials_img img {
      text-align: center;
      border-radius: 20px;
      min-height: 200px;
      overflow: hidden;
      position: relative;
      background: white;
      /* justify-content: center; */
      /* align-items: center; */
      border: 1px solid #80808029;
      object-fit: contain;
      max-width: 100%;
      padding: 0 1rem;
    }
  }
}

@media (min-width: 1300px) and(max-width:1500px) {
  .Banner .carousel-inner {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
  }
}

@media (min-width: 1501px) {
  .Banner .carousel-inner {
    position: relative;
    width: 100%;
    height: 810px;
    overflow: hidden;
  }
}

@media (min-width: 1700px) {
  .Banner .carousel-inner {
    position: relative;
    width: 100%;
    height: 910px;
    overflow: hidden;
  }
}

.sec_seven .evaluate_img .card-img-top {
  cursor: pointer;
  height: 225px;
  object-fit: fill;
  margin: 0;
}

.pain-level {
  display: flex;

  .pain {
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid $gray-200;
  }

  .level-1 {
    border-color: #85c14b;

    &.active {
      background-color: #85c14b;
    }
  }

  .level-2 {
    border-color: #95b634;

    &.active {
      background-color: #95b634;
    }
  }

  .level-3 {
    border-color: #95b634;

    &.active {
      background-color: #95b634;
    }
  }

  .level-4 {
    border-color: #b09c01;

    &.active {
      background-color: #b09c01;
    }
  }

  .level-5 {
    border-color: #bb8e00;

    &.active {
      background-color: #bb8e00;
    }
  }

  .level-6 {
    border-color: #c47f00;

    &.active {
      background-color: #c47f00;
    }
  }

  .level-7 {
    border-color: #cc6f04;

    &.active {
      background-color: #cc6f04;
    }
  }

  .level-8 {
    border-color: #d25e18;

    &.active {
      background-color: #d25e18;
    }
  }

  .level-9 {
    border-color: #d64b27;

    &.active {
      background-color: #d64b27;
    }
  }

  .level-10 {
    border-color: #d73535;

    &.active {
      background-color: #d73535;
    }
  }

  .level-selected-color {
    background-color: $primary;
    border: 1px solid $primary;
    color: white;
  }
}

.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 50s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(400px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-400px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.immediatereveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.immediatereveal.active {
  transform: translateY(0);
  opacity: 1;
}

.immediatereveal_LR {
  position: relative;
  transform: translateX(-150px);
  opacity: 0;
  transition: 1s all ease;
}

.immediatereveal_LR.active {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 576px) {
  .sec_one {
    padding: 100px 0px;
  }

  .sec_three {
    padding: 100px 0px;
  }

  .sec_eight {
    padding: 100px 0px;
  }

  .sec_nine {
    padding: 0px 30px 50px 30px;
  }

  .sec_one,
  .sec_three,
  .sec_four,
  .sec_five,
  .sec_six,
  .sec_eight {
    .btn-primary {
      font-size: 16px;
    }
  }

  .sec_nine {
    .btn-primary-50 {
      font-size: 16px;
    }

    a {
      font-size: 15px;
    }
  }

  .proven-tab p {
    font-size: 16px;
  }

  .content_02,
  .content_01,
  .content_03 h5 {
    font-size: 20px;
  }

  .sec_five .card {
    width: 100%;
    height: 80%;
    padding: 30px;
    right: 95px;
    // top: 105px;
    z-index: 1;
    border-radius: 20px;
    margin-top: 125px;
  }

  .testimonials_title {
    padding-left: 0px !important;
  }

  .sec_five .testimonials_img {
    border-radius: 12px;
  }

  .sec_five .logo_testimonials {
    width: 150px;
  }

  .sec_six {
    h1 {
      font-size: 24px;
    }
  }

  .sec_six {
    .accordion-header {
      .accordion-button {
        font-size: 16px !important;
      }
    }
  }

  .sec_three,
  .sec_seven {
    .slider {
      padding-left: 20px !important;
      width: 100%;
      padding-bottom: 1rem;
    }
  }

  footer {
    padding: 60px 0px 0px 0px !important;

    .footer-pad {
      padding-bottom: 35px !important;
    }
  }

  .sec_seven {
    .evaluate_img {
      min-width: 285px !important;
      margin-right: 24px;

      .card-img-top {
        width: 100% !important;
      }
    }
  }
}

.loading {
  filter: blur(5px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.3s linear;
}

.fillBookmarkFill {
  width: 35px;
  color: #9435ec;
  height: 35px;
  margin-right: 1rem;
}

.blogpage-like {
  width: 30px;
  height: 30px;
}

.h-50px {
  height: 50px;
}

.h-10rem {
  height: 10rem;
}

.eduarticle-header {
  position: sticky;
  top: 0;
  z-index: 1021;
}

.eduarticle-option {
  line-height: 16px;
  height: 48px;
}

.patDashboard-width {
  width: 1.8rem;
}

.refferal-resol {
  width: 15px;
  height: 15px;
}

.create-referral {
  text-decoration: none;
  border-radius: 10px;
}

.h-60px {
  height: 60px;
}

.background-lavender {
  background-color: lavender;
}

.transform-capitalize {
  text-transform: capitalize;
}

.fontweight-bolder {
  font-weight: bolder;
}

.fontweight-bold {
  font-weight: bold;
}

.chat-massage {
  height: 400px;
  min-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.pressdetails-loader {
  height: 4rem;
  text-align: left;
  margin: 50px 30rem;
}

.pressdetails-spinner {
  width: 3rem;
  height: 3rem;
  margin-left: 2rem;
}

.h-390px {
  height: 390px;
}

.borderradius-10px {
  border-radius: 10px;
}

.borderradius-15px {
  border-radius: 15px;
}

.borderradius-22px {
  border-radius: 22px;
}

.education-page-modal {
  height: 450px;
  overflow: auto;
}

.education-page-modal-close {
  float: right;
  cursor: pointer;
  font-size: 22px;
}

.liststyletype-none {
  list-style-type: none;
}

.pointerevents-all {
  pointer-events: all;
}

.pointerevents-none {
  pointer-events: none;
}

.border-bottom-2px-solid-black {
  border-bottom: 2px solid black;
}

.width-150px {
  width: 150px;
}

.height-150px {
  height: 150px;
}

.maxwidth-90px {
  max-width: 90px;
}

.width-90px {
  width: 90px;
}

.height-90px {
  height: 90px;
}

.imageDownload-hovered {
  position: absolute;
  top: 80%;
  left: 80%;
  transform: translate(-50%, -50%);
  border: none;
  cursor: pointer;
  background: none;
}

.height-25px {
  height: 25px;
}

.height-440px {
  height: 440px;
}

.background-00000061 {
  background: #00000061;
}

.background-transparent {
  background: transparent;
}

.width-25px {
  width: 25px;
}

.width-20px {
  width: 20px;
}

.width-280px {
  width: 280px;
}

.width-195px {
  width: 195px;
}

.width-14px {
  width: 14px;
}

.height-20px {
  height: 20px;
}

.height-60px {
  height: 60px;
}

.height-14px {
  height: 14px;
}

.height-15px {
  height: 15px;
}

.width-15px {
  width: 15px;
}

.height-70px {
  height: 70px;
}

.height-4903px {
  height: 49.3px;
}

.width-70px {
  width: 70px;
}

.width-26px {
  width: 26px;
}

.width-35px {
  width: 35px;
}

.height-35px {
  height: 35px;
}

.height-80px {
  height: 80px;
}

.width-fit-content {
  width: fit-content;
}

.height-fit-content {
  height: fit-content;
}

.height-200px {
  height: 200px;
}

.height-540px {
  height: 540px;
}

.width-300px {
  width: 300px;
}

.width-100px {
  width: 100px;
}

.width-96px {
  width: 96.5px;
}

.width-40px {
  width: 40px;
}

.width-55px {
  width: 55px;
}

.height-40px {
  height: 40px;
}

.alignContent-center {
  align-content: center;
}

.justifyContent-space-between {
  justify-content: space-between;
}

.justifyContent-center {
  justify-content: center;
}

.justifyContent-flex-start {
  justify-content: flex-start;
}

.justifyContent-flex-end {
  justify-content: flex-end;
}

.flex-1 {
  flex: 1;
}

.padding-052rem {
  padding: 0.52rem;
}

.padding-08rem {
  padding: 0.8rem;
}

.padding-064rem {
  padding: 0.64rem;
}

.padding-092rem {
  padding: 0.92rem;
}

.margintop--1px {
  margin-top: -1px;
}

.top-26 {
  top: 26%;
}

.videomodal-showdata {
  height: 4rem;
  text-align: center;
  margin: 50px auto;
}

.width-500px {
  width: 500px;
}

.width-8rem {
  width: 8rem;
}

.width-3rem {
  width: 3rem;
}

.height-3rem {
  height: 3rem;
}

.height-40rem {
  height: 40rem;
}

.height-0 {
  height: 0;
}

.height-450px {
  height: 450px;
}

.height-250px {
  height: 250px;
}

.minwidth-100 {
  min-width: 100%;
}

.maxheight-450px {
  max-height: 450px;
}

.maxheight-25rem {
  max-height: 25rem;
}

.maxheight-350px {
  max-height: 350px;
}

.maxheight-100px {
  max-height: 100px;
}

.maxheight-250px {
  max-height: 250px;
}

.maxheight-4903px {
  max-height: 49.3px;
}

.maxheight-15rem {
  max-height: 15rem;
}

.maxheight-100 {
  max-height: 100%;
}

.maxheight-20rem {
  max-height: 20rem;
}

.maxheight-3rem {
  max-height: 3rem;
}

.height-80 {
  height: 80%;
}

.wordBreak-break-word {
  word-break: break-word;
}

.wordBreak-breakall {
  word-break: break-all;
}

.fontsize-smaller {
  font-size: smaller;
}

.minheight-15rem {
  min-height: 15rem;
}

.minwidth-201rem {
  min-width: 2.1rem;
}

.minheight-201rem {
  min-height: 2.1rem;
}

.fontsize-20px {
  font-size: 20px;
}

.fontsize-14px {
  font-size: 14px;
}

.margintop-10px {
  margin-top: 10px;
}

.margintop--10px {
  margin-top: -10px;
}

.margintop-5rem {
  margin-top: 5rem;
}

.margintop-1rem {
  margin-top: 1rem;
}

.margintop-26rem {
  margin-top: 26rem;
}

.margintop-21rem {
  margin-top: 21rem;
}

.marginBottom-16px {
  margin-bottom: 16px;
}

.marginBottom-2px {
  margin-bottom: 2px;
}

.paddingtop-10rem {
  padding-top: 10rem;
}

.margintop-10rem {
  margin-top: 10rem;
}

.height-30px {
  height: 30px;
}

.width-30px {
  width: 30px;
}

.visibility-visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
}

.border-0 {
  border: 0;
}

.menu-icon {
  vertical-align: 0px;
  margin-left: 0.2rem;
}

.referral-image {
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-width: 70px;
  position: relative;
  object-fit: cover;
}

.referral-tdata {
  text-align: center;
  border-color: white;
}

.resetPassword-text {
  border-right: 1px solid #00000099;
}

.width-108rem {
  width: 1.8rem;
}

.text-decoration-none {
  text-decoration: none;
}

.backgroundcol0r-f5f5f7 {
  background-color: #f5f5f7;
}

.moplikedexer-loading {
  height: 4rem;
  text-align: center;
  margin: 50px auto;
}

.backgroundcolor-yellow {
  background-color: yellow;
}

.claimyourprofile {
  display: block;
  padding: 30px;
}

.testimonial-label {
  color: #757579;
  font-size: 17px;
}

.testimonial-image {
  padding: 0px 0rem;
  width: 16rem;
}

.padding-12px-35px {
  padding: 12px 35px;
}

.createreferral-label-div {
  background-color: #e7e9eb;
  justify-content: center;
  align-content: center;
  height: 30px;
  width: 100px;
  border-color: black;
  border-width: 0.8px;
  border-radius: 4px;
  border-style: solid;
}

.createreferral-label-text {
  font-weight: 400;
  margin-top: 2px;
}

.createreferral-loading-text {
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overflowWrap-break-word {
  overflow-wrap: break-word;
}

.overflowWrap-anywhere {
  overflow-wrap: anywhere;
}

.padding-4rem {
  padding: 4rem;
}

.profile-tab-loading {
  height: 4rem;
  text-align: center;
  margin: 40px auto;
}

.overflowY-auto {
  overflow-y: auto;
}

.overflowY-scroll {
  overflow-y: scroll;
}

.overflowX-hidden {
  overflow-x: hidden;
}

.height-175vh {
  height: 175vh;
}

.aspectRatio-16-9 {
  aspect-ratio: 16/9;
}

.wordWrap-break-word {
  word-wrap: break-word;
}

.lineHeight-44px {
  line-height: 44px;
}

.lineHeight-30px {
  line-height: 30px;
}

.referral-detail-header {
  margin-top: -6px;
  margin-left: 10px;
  color: black;
}

.referral-detail-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border-width: 3px;
  border-color: red($color: #000000);
}

.alignItems-center {
  align-items: center;
}

.flexDirection-column {
  flex-direction: column;
}

.padding-bottom-20px {
  padding-bottom: 20px;
}

.texttransform-cap {
  text-transform: capitalize;
}

.background-e6e6e6 {
  background: #e6e6e6;
}

.paddingStart-03rem {
  padding-left: 0.3rem;
}

.textAlign-inherit {
  text-align: inherit;
}

.feeds-attachfiles {
  font-weight: 400;
  margin-top: 2px;
}

.objectFit-cover {
  object-fit: cover;
}

.objectFit-contain {
  object-fit: contain;
}

.color-105692 {
  color: #105692;
}

.gap-16px {
  gap: 16px;
}

.provider-select-div {
  width: 90%;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.provider-select-ul {
  margin: 10px;
  padding: 0;
  list-style-type: none;
}

.provider-select-li {
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid grey;
}

.provider-select-li:hover {
  background-color: #f3f4f6;
}

.cookiebottom {
  position: fixed;
  z-index: 400;
  width: 80%;
  bottom: 0;
  left: 40%;
  transform: translateX(-50%);
}

.cookie-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  background-color: #004aa0;
  color: white;
}

.cookie-close {
  float: right;
  cursor: pointer;
}
