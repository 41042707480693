.wrappers {
  position: relative;
  overflow-x: hidden;
  max-width: 1000px;
  background: #fff;
  border-radius: 13px;
}
.wrappers .icon {
  position: absolute;
  top: 15%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  border-radius: 50%;
}
.icon:first-child {
  left: 0;
  justify-content: center;
  background: linear-gradient(90deg, #fff 70%, transparent);
}
.icon:last-child {
  right: 0;
  justify-content: center;
  background: linear-gradient(-90deg, #fff 70%, transparent);
}
.icon svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 55px;
  border-radius: 50%;
}
.icon svg:hover {
  background: #efedfb;
}
.wrappers .tabs-box {
  display: flex;
  gap: 12px;
  list-style: none;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.tabs-box.dragging {
  scroll-behavior: auto;
  cursor: grab;
}
