//pricing
.bg-warning-10 {
  background-color: #f5f5f7 !important;
}
.bg-purple-10 {
  background-color: #e5edf5 !important;
}
.xs-small {
  font-size: 10px !important;
}
